import { CityEnum, location } from "@vuumly-common/common";
import { useLocation } from "react-router-dom";
import {
  RideOriginCard,
  RideOriginDestinationCard,
  RidesTotalCities,
} from "../../components/Card";
import { DashboardHeader } from "../../components/Header";
import { SuburbMap } from "../../components/Map";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/shadcn/components/ui/tabs";
import { HoveredFeature } from "../../context";
import { RoutePathEnum } from "../../controllers/router";
import { useCity, useDistrict, usePageTitle, useRouter } from "../../hooks";

export const AreasPage = () => {
  const { setSelectedCities } = useCity();
  const { setSelectedDistrict } = useDistrict();
  const title = usePageTitle(RoutePathEnum.Areas);
  const { goTo } = useRouter();
  const { pathname } = useLocation();

  function handleClick(item: HoveredFeature | null) {
    const id = item?.feature.getProperties().properties.id;
    const city = item?.feature.getProperties().properties.city as CityEnum;
    const adminLevel = item?.feature.getProperties().properties.admin_level;

    if (
      adminLevel === location.cities.AdminLevelEnum.City ||
      adminLevel === location.cities.AdminLevelEnum.Municipality
    ) {
      setSelectedCities([city]);
    } else if (
      adminLevel === location.cities.AdminLevelEnum.District ||
      adminLevel === "suburb"
    ) {
      setSelectedDistrict(id);
      goTo(RoutePathEnum.Rides, { replace: true });
    }
  }

  return (
    <>
      <DashboardHeader title={title} />

      <Tabs onValueChange={goTo} value={pathname}>
        <TabsList className="mb-8">
          <TabsTrigger value={RoutePathEnum.Areas}>Ride origin</TabsTrigger>
          <TabsTrigger value={RoutePathEnum.AreasOriginDestination}>
            Ride destination
          </TabsTrigger>
          <TabsTrigger value={RoutePathEnum.AreasMap}>Map</TabsTrigger>
        </TabsList>

        <TabsContent value={RoutePathEnum.Areas}>
          <div className="flex flex-col space-y-12">
            <RideOriginCard enablePagination ignoreOriginIdFilter />
            <RidesTotalCities enablePagination />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.AreasOriginDestination}>
          <div className="flex flex-col space-y-12">
            <RideOriginDestinationCard />
          </div>
        </TabsContent>

        <TabsContent value={RoutePathEnum.AreasMap}>
          <div className="flex flex-col space-y-12">
            <SuburbMap
              className="rounded-lg overflow-hidden"
              style={{ height: 600 }}
              onClick={handleClick}
            />
          </div>
        </TabsContent>
      </Tabs>
    </>
  );
};
